@media (min-width: 1280px) {
    #main-grid {
        width: calc(100% - 280px);
        margin-left: 280px;
    }
}

.pulse {
    background-color: #2196f3 !important;
    color: 'white';
    animation-name: pulse;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    box-shadow: 0 0 0 0 rgba(50, 50, 50, 0.6);
}

@keyframes pulse {
    100% {
        box-shadow: 0 0 0 15px rgba(0, 0, 0, 0);
    }
}
