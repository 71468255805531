.pulse {
    background-color: #2196f3 !important;
    color: 'white';
    animation-name: pulse;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.6);
}

@keyframes pulse {
    100% {
        box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
    }
}
